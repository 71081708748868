import 'stylesheets/pages'
import Alpine from 'alpinejs'
import Mixpanel from '@/utils/mixpanel'

require.context('../images', true)

window.Alpine = Alpine
window.Mixpanel = Mixpanel

Alpine.start()
